import React from 'react'
import {Alert} from 'reactstrap'
import {connect} from 'react-redux'
import { addVeranstaltungDimissAlert } from '../store/alerts/actions'

export const messageOn403 =" Die Kombination VNR/ Passwort wurde abgewiesen. Bitte überprüfen Sie Ihre Eingaben."
export const messageOn403JWT =" Der Einrichtungstoken wurde abgewiesen. Mögliche Ursache - ein falscher Token oder der Token ist nicht mehr gültig."

const AddVeranstaltungAlert = (props) => {
	const {isOpen = false, onDimiss = null,
	message = messageOn403 } = props
	return(
		<Alert color="danger" isOpen={isOpen} toggle={ () => onDimiss()} >{message}</Alert>
	)
}
const mapStateToProps = (state) => {
	return {
		isOpen: state.alerts.addVeranstaltungAlertIsOpen,
		message: state.alerts.addVeranstaltungAlertMessage,
		
	}
} 
const mapDispatchToProp = (dispatch) => ({
	onDimiss: () => dispatch(addVeranstaltungDimissAlert()),
})
export default connect(mapStateToProps, mapDispatchToProp)(AddVeranstaltungAlert)
//import React from 'react';
import { connect } from 'react-redux'
import { setScreen, appScreens } from '../store/appNavigation/actions';
import { setEFN, setAuthToken, setupEFN} from '../store/efnholder/actions'
import { EFNSetupEingabe } from './EFNSetupEingabe';

const mapStateToProps = (state, ownProps) => (
	{
		efn : state.efnHolder.efn,
		authToken : state.efnHolder.authToken,
		isValidEFN : state.efnHolder.isValidEFN,
		efnSetupCompleted : state.efnHolder.efnSetupCompleted,
		networkActionsDisabled : state.fetchCounter > 0
	}
)

// \#;#FC#;#802760100042638#;#secret#;#
const putActionsToProps = dispatch => {
	return {
		close : () => dispatch(setScreen(appScreens.startPage)),
		changeEFN : (efn) => dispatch(setEFN(efn)),
		changeAuthToken : (authToken) => dispatch(setAuthToken(authToken)),
		setupEFN: (efn, authToken) => dispatch(setupEFN(efn, authToken))
	}
};

export default connect(mapStateToProps, putActionsToProps)(EFNSetupEingabe);
import React from 'react'
import {Alert} from 'reactstrap'
import {connect} from 'react-redux'
import { efnHolderDimissAlert } from '../store/alerts/actions'

export const messageOn403 =" Die Kombination EFN/ Autorisierungs- Passwort wurde abgewiesen. Bitte überprüfen Sie Ihre Eingaben."

const EFNHolderAlert = (props) => {
	const {isOpen = false, onDimiss = null,
	message = messageOn403 } = props
	return(
		<Alert color="danger" isOpen={isOpen} toggle={ () => onDimiss()} >{message}</Alert>
	)
}
const mapStateToProps = (state) => {
	return {
		isOpen: state.alerts.efnHolderAlertIsOpen,
		message: state.alerts.efnHolderAlertMessage,
		
	}
} 
const mapDispatchToProp = (dispatch) => ({
	onDimiss: () => dispatch(efnHolderDimissAlert()),
})
export default connect(mapStateToProps, mapDispatchToProp)(EFNHolderAlert)
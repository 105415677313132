import { connect } from 'react-redux'
import { setEFN, setAuthToken, fetchPunktemeldungenOfEFNHolder, setVisibleScanMyEFNDialog} from '../store/efnholder/actions'
import { Punktemeldungen } from './EFNHolderPunktemeldungen';


const mapStateToProps = (state, ownProps) => (
	{
		efn : state.efnHolder.efn,
		authToken : state.efnHolder.authToken,
		bearer : state.efnHolder.bearer,
		efnSetupCompleted : state.efnHolder.efnSetupCompleted,
		networkActionsDisabled : state.fetchCounter > 0,
		notificationsSupported : state.notifications.supported
	}
)
const putActionsToProps = dispatch => {
	return {
		changeEFN : (efn) => dispatch(setEFN(efn)),
		changeAuthToken : (authToken) => dispatch(setAuthToken(authToken)),
		fetchPunktemeldungen: (efn, bearer) => dispatch(fetchPunktemeldungenOfEFNHolder(efn, bearer)),
		showScanMyEFNDialog: () => dispatch(setVisibleScanMyEFNDialog(true))
	}
};

export default connect(mapStateToProps, putActionsToProps)(Punktemeldungen);
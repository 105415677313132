import React from 'react';
import EIVHeader from './EIVHeader';
import { Button } from 'reactstrap'
import { CSS_CLASSNAME_PANEL } from './EIVCommons';
import Veranstaltungen from './components/Veranstaltungsliste';
import AddVeranstaltungPanel from './AddVeranstaltungPanel'
import { setScreen } from './store/appNavigation/actions';
import { connect } from 'react-redux'
import { addVeranstaltungPanelSetVNR, addVeranstaltungPanelSetVNRPwd, setShowAddVeranslatungsPanel } from './store/veranstaltungen/actions';


const VeranstalterPage = (props) => {
	const { showAddVeranstaltungArea, setShowAddVeranslatungsPanel } = props
	return (
		<div className="container">
			<EIVHeader></EIVHeader>
			<div className="h3">Meine Veranstaltungen</div>
			<div className={CSS_CLASSNAME_PANEL}>
				<Veranstaltungen />
				<br />
				{!showAddVeranstaltungArea &&
					<Button color="secondary" /*outline*/ onClick={() => setShowAddVeranslatungsPanel(true)} >Veranstaltung hinzufügen</Button>
				}
				{showAddVeranstaltungArea &&
					<AddVeranstaltungPanel toggleAddVNRPanel={() => setShowAddVeranslatungsPanel(false)}>
					</AddVeranstaltungPanel>
				}
			</div>


		</div>
	)
}
const mapStateToProps = (state, ownProps) => {
	return {
		showAddVeranstaltungArea: state.addVeranstaltungPanel.showAddVeranstaltungArea
	}
}
const putActionsToProps = dispatch => {
	return {
		setScreen: (screen) => dispatch(setScreen(screen)),
		changeVNR: vnr => dispatch(addVeranstaltungPanelSetVNR(vnr)),
		changeVNRPwd: vnrpwd => dispatch(addVeranstaltungPanelSetVNRPwd(vnrpwd)),
		setShowAddVeranslatungsPanel: flag => dispatch(setShowAddVeranslatungsPanel(flag))
	}
};

export default connect(mapStateToProps, putActionsToProps)(VeranstalterPage);
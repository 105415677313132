import React from 'react';

import {connect} from 'react-redux'
import VeranstaltungsItem  from './VeranstaltungsItem';
import KeineVeranstaltungen from './KeineVeranstaltungen';

const veranstaltungenCompare = ( a, b ) => {
  if ( a.beginn < b.beginn ){
    return 1;
  }
  if ( a.beginn > b.beginn ){
    return -1;
  }
  return 0;
}

const DimissableAlertHintDeleteNotNeededVeranstaltungen = ({ onDismiss }) => {
	return (
		<div className="alert alert-info alert-dismissible">
			<button type="button" className="close" data-dismiss="alert" aria-hidden="true" onClick={onDismiss}>&times;</button>
			<strong>Hinweis:</strong> Um Ihre Liste übersichtlich zu halten und Speicherplatz zu sparen, empfehlen wir, nicht mehr benötigte Veranstaltungen zu entfernen. Sie können diese bei Bedarf jederzeit wieder hinzufügen.
		</div>
	)
}

const Veranstaltungsliste = (props) => {
	const countOfVeranstaltungen = Object.keys(props.veranstaltungsData).length;
	const veranstaltungen = Object.values(props.veranstaltungsData).sort(veranstaltungenCompare);
	const {punkteMeldung} = props
	const keineVeranstaltungen = undefined === props.veranstaltungsData || Object.keys(props.veranstaltungsData).length === 0;
	const [showAlert, setShowAlert] = React.useState(true);

	React.useEffect(() => {
		setShowAlert(true);
	}, [props.veranstaltungsData]);

	if(keineVeranstaltungen) {
		return (
			<KeineVeranstaltungen />
		)
	}

	const handleDismissAlert = () => {
		setShowAlert(false);
	}

	return (
		<>
			{countOfVeranstaltungen > 5 && showAlert && 
				<DimissableAlertHintDeleteNotNeededVeranstaltungen onDismiss={handleDismissAlert} />
			}		
			<ul className="list-group">
			{
				veranstaltungen.map((veranstaltung) =>  (
					<li className="list-group-item" key={veranstaltung.vnr}>
					<VeranstaltungsItem countErfasste={ punkteMeldung.erfassteTeilnahmen[veranstaltung.vnr]===undefined?
							0:Object.keys(punkteMeldung.erfassteTeilnahmen[veranstaltung.vnr]).length}
							data={veranstaltung} />
					</li>))
			}
			
		</ul>
	</>
	);
}

const mapStateToProps = function(state) {
  return { 
		veranstaltungsData: state.veranstaltungen,
		punkteMeldung: state.punkteMeldung
	}
}

export default connect(mapStateToProps)(Veranstaltungsliste) 



				


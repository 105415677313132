import React from 'react';
import { connect } from 'react-redux'
import  moment  from 'moment';
import { Alert, Table } from 'reactstrap'
import { FlagComponentReadOnly } from './FlagComponentReadOnly';
import i18next from 'i18next';
import { isShowTeilnahmedatum } from './TeilnehmerMelden/teilnahmedatum';
import { countDistinctTeilnehmer } from './Veranstaltung';
import { ListOfPunktemeldungen } from './ListOfPunktemeldungen';
import { MAX_COUNT_PUNKTEMELDUNGEN } from '../store/punkteMeldung/fetchPunktemeldungen';

const TRPunktemeldung = (props) => {
	const { istInEingabe, punktemeldung, showTeilnahmedatum, showLernerfolg, className = "" } = props
	const teilnahmedatum = punktemeldung.teilnahmedatum === null?null:moment(punktemeldung.teilnahmedatum).format('DD.MM.YYYY')
	const referentenPunkte = punktemeldung.punkte_referent
	let classNameTR = istInEingabe?"text-primary":null
	return(
	<tr className={`${className} ${classNameTR}`}>
				{/* <td>{punktemeldung.id}</td> */}
				<td>{punktemeldung.efn}</td>
				{ showTeilnahmedatum &&
					<td>{teilnahmedatum}</td>
				}
				<td><FlagComponentReadOnly flag={punktemeldung.punkte_basis_flag} /></td>
				{ showLernerfolg &&
				<td><FlagComponentReadOnly flag={punktemeldung.punkte_lernerfolg_flag} /></td>
				}
				<td>{referentenPunkte}</td>
				<td>{moment(punktemeldung.last_modified).format('DD.MM.YYYY HH:mm:ss')}</td>			
	</tr>
	)
}
const mapStateToPropsTrPunktemeldung = (state, ownProps) => {
	return{
		istInEingabe: ownProps.punktemeldung.efn === state.punkteMeldung.addTeilnahme[ownProps.vnr].efn
	}
}
const PunktemeldungRow = connect(mapStateToPropsTrPunktemeldung)(TRPunktemeldung)
function KeinePunktemeldungen(props) {
  return (
		<Alert color="" className="h6 text-center mt-4 mb-4 lead">
			{i18next.t('keine.punktemeldungen')}
		</Alert>
	);
}
function punktemeldungenCompare( a, b ) {
  if ( a.last_modified < b.last_modified ){
    return 1;
  }
  if ( a.last_modified > b.last_modified ){
    return -1;
  }
  return 0;
}
const Punktemeldungen = (props) => {
	const { punkteMeldungen = [], showTeilnahmedatum = true, vnr, showLernerfolg = false, highLightRefpunkte = false } = props
	const sortedPunktemeldungen = Object.values(punkteMeldungen).sort(punktemeldungenCompare)
	const keys = Object.keys(punkteMeldungen)
	const countPunktemeldungen = keys.length
	const countGemeldeteTeilnehmer = countDistinctTeilnehmer(punkteMeldungen)
	const classNamePunktemeldungWithRefPunkte = highLightRefpunkte?"font-weight-bold bg-warning":"";
	if( countPunktemeldungen === 0 ) return (
		<KeinePunktemeldungen/>
	)
	return(
		<div>
		<h6>Teilnahmen: {countGemeldeteTeilnehmer}
		{ MAX_COUNT_PUNKTEMELDUNGEN === countGemeldeteTeilnehmer &&
			<> (*)</>
		}
		</h6>
		{ MAX_COUNT_PUNKTEMELDUNGEN === countGemeldeteTeilnehmer &&
			<span className='text-muted'>(*) - Maximal werden {MAX_COUNT_PUNKTEMELDUNGEN} letzten gemeldeten Teilnehmer geladen und angezeigt.</span>
		}
		<ListOfPunktemeldungen className="d-md-none" vnr = {vnr} classNamePunktemeldungWithRefPunkte = {classNamePunktemeldungWithRefPunkte}
			showTeilnahmedatum={showTeilnahmedatum} showLernerfolg={showLernerfolg} punkteMeldungen={sortedPunktemeldungen}
		/>
		<Table responsive size="sm" striped className="mt-4 mb-4 d-none d-md-table">
		<HeaderPunktemldungen showTeilnahmedatum={showTeilnahmedatum} showLernerfolg={showLernerfolg} />
		<tbody>
			{sortedPunktemeldungen.map((punktemeldung, index) => <PunktemeldungRow key={index} vnr = {vnr} className={punktemeldung.punkte_referent
> 0?classNamePunktemeldungWithRefPunkte:""}
			showTeilnahmedatum={showTeilnahmedatum} showLernerfolg={showLernerfolg} punktemeldung={punktemeldung}></PunktemeldungRow>)}
		</tbody>
  </Table>
		</div>
	)
}
const HeaderPunktemldungen = (props) => {
	const { showLernerfolg, showTeilnahmedatum } = props
	return (
		<thead>
			<tr>
				{/* <th>Meldung ID</th> */}
				<th>EFN</th>
				{ showTeilnahmedatum &&
					<th>Teilnahmedatum</th>
				}
				<th>Teilnahme</th>
				{ showLernerfolg &&
				<th>
					<span className=" d-lg-none">Lern- Erfolg</span>
					<span className="d-none d-lg-inline" >Lernerfolg</span>
				</th>
				}
				<th>Ref/Mod/WL Pkte.</th>
				<th>Meldedatum</th>			
			</tr>
		</thead>
	)
}

const mapStateToProps = (state, ownProps) => {
	return {
		punkteMeldungen: state.punkteMeldung.teilnahmeMeldungen[ownProps.vnr],
		showLernerfolg: state.veranstaltungen[ownProps.vnr].punkte_lernerfolg > 0,
		showTeilnahmedatum: isShowTeilnahmedatum(state.veranstaltungen[ownProps.vnr])
	}
}
export default connect(mapStateToProps)(Punktemeldungen)